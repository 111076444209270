@import 'antd/dist/reset.css';

.ui-upload {
  display: flex;
  margin-top: 8%;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .input-container {
    width: 50%;

    .drag-and-drop{
      .ant-upload-drag-icon {
        .logo-excel{
          max-width: 100px;
        }
      }
    }
    .ant-upload-list-item-name-icon-count-1 {
      background-color: white;
    }
    .ant-upload.ant-upload-drag {
      border: 2px solid #e8308a;

    }
  }

  .button-container {
    margin: 5%;
    width: 50%;
    display: flex;
    justify-content: space-around;
    Button {
      background-color: #e8308a;
      border-color: #e8308a;
      color: white;
      width: 100px;
    }
  }
}

@media screen and (max-width: 600px){
  .ui-upload {
    .button-container {
      margin: 10%;
    }
  }
}

.ant-btn-primary {
  background-color: #e8308a;
  border-color: #e8308a;
}