
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

.header-home {
  max-height: 15%;
  .header-logo {
    height: 130px;

    .logo-altyor {
      max-height: 100%;
      max-width: 100%;
    }
  }
  .header-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding-right: 1%;
    .title-container{
      max-height: 100%;
      max-width: 100%;
      text-align: right;
      color: white;
      font-family: 'Open Sans', sans-serif;

      em {
        font-style: normal;
        text-decoration: underline #e8308a;
        text-underline-position: under;
        text-underline-offset: 3px;
      }
    }
  }
}

