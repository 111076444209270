@import 'antd/dist/reset.css';

.global-ui {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .background {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(45%)
  }
  .dashboard {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    .header-container {
      top: 0;
      left: 0;
      height: 20%;
      width: 100%;
    }
    .ui-converter {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

    }
  }
}

@media screen and (max-width: 1000px){
  .global-ui {
    width: 100%;
    .dashboard {
      width: 100%;
      .ui-converter {
        width: 100%;
        align-items: center;
      }

    }
  }
}
